<template>
  <div>
    <div class="right-box">
      <el-button type="primary" @click="showDetailBox">添加封禁人员</el-button>
    </div>
    <el-table class="mb-20" stripe border :data="list">
      <el-table-column align="center" prop="name" label="封禁人员姓名"/>
      <el-table-column align="center" prop="phone" label="封禁人员电话"/>
      <el-table-column align="center" label="操作">
        <template #default>
          <el-button type="text">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-model:page="params.page"
      v-model:limit="params.per_page"
      :total="total"
    />
    <el-dialog
      v-model="detailBox"
      title="添加封禁人员"
      width="500px"
    >
      <el-form label-width="100px">
        <el-form-item label="封禁用户">
          <el-autocomplete
            v-model="form.id"
            :fetch-suggestions="querySearchAsync"
            placeholder="请搜索用户"
            @select="handleSelect"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <div>
          <el-button type="primary">保存</el-button>
          <el-button @click="hideDetailBox">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'BanRunner',
  data() {
    return {
      params: {
        page: 1,
        per_page: 20,
      },
      total: 1,
      list: [],
      detailBox: false,
      timeout: null,
      form: {
        user_id: ''
      }
    };
  },
  methods: {
    showDetailBox() {
      this.detailBox = true;
    },
    hideDetailBox() {
      this.detailBox = false;
    },
    querySearchAsync(queryString, cb)  {
      const results = queryString
      console.log(results);
    },
    handleSelect(item) {
      console.log(item)
    }
  },
};
</script>

<style scoped>

</style>
